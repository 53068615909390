:root {
  background: linear-gradient(0deg, rgba(114,143,139,1) 0%, rgba(90,158,149,1) 47%, rgba(90,158,149,1) 100%);
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #F5CA09
}

.main-text {
  font-size: 30px;
  margin-top: 10px;
  font-family: 'Poppins', serif;
  font-weight: 700;
  color: white;
}

.sub-text {
  color: rgb(240, 239, 239);
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
}

.info-text {
  color: #263238;
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
  width: 90vw;
  max-width: 300px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #263238;
  cursor: pointer;
  padding: 13px 0;
  border-radius: 30px;
  position: relative;
}

.icon-container {
  position: absolute;
  left: 20px;
}

.button-text {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Poppins', serif;
}

a {
  text-decoration: none;
  color: #263238;
}